import React, { ChangeEvent, useCallback, useState } from 'react';
import { useRootStore } from 'stores/StoreProvider';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from '@mui/material';
import { CreateStickerFormContainer, DrawerContainer, SubmitBtn } from './styles';
import { EStickerProperty } from 'stores/stickerStore/constants';
import { RangeInput } from './components/RangeInput';
import { ColorPicker } from './components/ColorPicker';
import { ESticker } from 'constants/main';
import { ConfirmationModal } from './components/ConfirmationModal';
import { TitleWithActions } from 'ui/TitleWithActions';

export const StickerCreateDrawer = observer(() => {
  const {
    stickerStore,
    isDrawerOpen,
    isPaymentModalOpen,
    setDrawerOpen,
    setPaymentModalOpen,
  } = useRootStore();

  const {
    sticker,
    isStickerOverlapsMenu,
    setSticker,
    setStickerToDefault,
    handleDeleteSticker,
  } = stickerStore;

  const handleChange = useCallback(
    (property: EStickerProperty, value: string | number) => {
      setSticker(property, value);
    },
    [setSticker],
  );

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSticker(EStickerProperty.Text, event.target.value);
    },
    [setSticker],
  );

  if (!sticker) return null;

  return (
    <DrawerContainer id='menu' open={isDrawerOpen} $isHidden={isStickerOverlapsMenu}>
      <TitleWithActions
        title='Добавить новый стикер'
        onClick={() => setDrawerOpen(false)}
      />

      <CreateStickerFormContainer>
        <RangeInput
          label='Высота'
          value={sticker.height}
          min={ESticker.MinHeight}
          max={ESticker.MaxHeight}
          step={ESticker.SizeStep}
          onChange={(newHeight) => handleChange(EStickerProperty.Height, newHeight)}
        />
        <RangeInput
          label='Ширина'
          value={sticker.width}
          min={ESticker.MinWidth}
          max={ESticker.MaxWidth}
          step={ESticker.SizeStep}
          onChange={(newWidth) => handleChange(EStickerProperty.Width, newWidth)}
        />
        <ColorPicker
          label='Цвет фона'
          color={sticker.stickerColor}
          onChange={(newColor) => handleChange(EStickerProperty.StickerColor, newColor)}
        />
        <ColorPicker
          label='Цвет текста'
          color={sticker.textColor}
          onChange={(newColor) => handleChange(EStickerProperty.TextColor, newColor)}
        />

        <TextField
          label='Текст'
          placeholder='Введите текст...'
          size='small'
          variant='outlined'
          minRows={3}
          maxRows={3}
          inputProps={{
            maxLength: 150,
          }}
          multiline
          value={sticker.text}
          onChange={handleTextChange}
        />

        <div>
          <Button onClick={setStickerToDefault}>Сбросить значения</Button>
          <Button color='error' onClick={handleDeleteSticker}>
            Удалить стикер
          </Button>
        </div>
      </CreateStickerFormContainer>

      <SubmitBtn variant='contained' onClick={() => setPaymentModalOpen(true)}>
        Добавить стикер
      </SubmitBtn>

      <ConfirmationModal
        open={isPaymentModalOpen}
        handleClose={() => setPaymentModalOpen(false)}
      />
    </DrawerContainer>
  );
});
