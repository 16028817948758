export enum EColors {
  AppBg = '#2a2a36',

  Black = '#000000',
  White = '#FFFFFF',

  Beige = '#FFE3A6',

  Primary = '#232323',
  Secondary = '#898989',

  TextSecondary = '#B0B0B0',
}
