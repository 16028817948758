import React from 'react';
import { ISticker } from 'services/stickers/interfaces';
import { StickerStyled } from 'ui/stickerStyles';

interface IStickerProps {
  item: ISticker;
}

export const Sticker: React.FC<IStickerProps> = ({ item }) => {
  const { x, y, width, height, text, stickerColor, textColor, fontSize } = item;

  return (
    <StickerStyled
      style={{
        left: `${x}px`,
        top: `${y}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: stickerColor,
        color: textColor,
        fontSize: `${fontSize}px`,
      }}
    >
      <div>{text}</div>
    </StickerStyled>
  );
};
