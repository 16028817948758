export const APP_NAME = 'Sticker Board';

const STICKER_MIN_HEIGHT = 100;
const STICKER_MIN_WIDTH = 100;

export enum ECanvas {
  Width = 1580,
  Height = 600,

  Padding = 10,
}

export enum ESticker {
  MinWidth = STICKER_MIN_WIDTH,
  MinHeight = STICKER_MIN_HEIGHT,

  MaxWidth = 200,
  MaxHeight = 200,

  DefaultWidth = STICKER_MIN_WIDTH,
  DefaultHeight = STICKER_MIN_HEIGHT,

  SizeStep = 5,
}
