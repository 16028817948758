import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
  >
    <CircularProgress />
  </Box>
);
