import { ECanvas } from 'constants/main';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import { IStickerDraggable } from 'stores/stickerStore/interfaces';
import { DraggableContainer } from './styles';

import fitty from 'fitty';
import { useRootStore } from 'stores/StoreProvider';
import { EStickerProperty } from 'stores/stickerStore/constants';
import { ResizeIcon } from './components/ResizeIcon';

interface IDraggable {
  item: IStickerDraggable;
  onDragEnd: (x: number, y: number) => void;
}

export const Draggable: React.FC<IDraggable> = observer(({ item, onDragEnd }) => {
  const { stickerStore, isDrawerOpen, setDrawerOpen } = useRootStore();
  const { setIsStickerOverlapsMenu, setSticker } = stickerStore;

  const { x, y, width, height, text, stickerColor, textColor } = item;
  const textRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [posX, setPosX] = useState(x);
  const [posY, setPosY] = useState(y);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const [isResizing, setIsResizing] = useState(false);
  const [startWidth, setStartWidth] = useState(width);
  const [startHeight, setStartHeight] = useState(height);

  const isOverlappingMenu = (stickerRect: DOMRect, menuRect: DOMRect) => {
    return !(
      stickerRect.right < menuRect.left ||
      stickerRect.left > menuRect.right ||
      stickerRect.bottom < menuRect.top ||
      stickerRect.top > menuRect.bottom
    );
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setStartX(event.clientX - posX);
    setStartY(event.clientY - posY);
    setIsDragging(true);
  };

  const handleResizeMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    setStartWidth(width);
    setStartHeight(height);
    setStartX(event.clientX);
    setStartY(event.clientY);
    setIsResizing(true);
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isResizing) {
        const newWidth = startWidth + (event.clientX - startX);
        const newHeight = startHeight + (event.clientY - startY);

        setSticker(EStickerProperty.Width, newWidth > 0 ? newWidth : 0);
        setSticker(EStickerProperty.Height, newHeight > 0 ? newHeight : 0);
      } else if (isDragging) {
        let newX = event.clientX - startX;
        let newY = event.clientY - startY;

        newX = Math.max(0, Math.min(newX, ECanvas.Width - width));
        newY = Math.max(0, Math.min(newY, ECanvas.Height - height));

        setPosX(newX);
        setPosY(newY);

        const menu = document.getElementById('menu');
        if (menu) {
          const menuRect = menu.getBoundingClientRect();
          const stickerRect = {
            top: newY,
            left: newX,
            right: newX + width,
            bottom: newY + height,
          };

          const overlaps = isOverlappingMenu(stickerRect as DOMRect, menuRect);

          if (overlaps && isDrawerOpen) {
            setDrawerOpen(false);
          } else if (
            !overlaps &&
            stickerRect.right < menuRect.left - menuRect.width * 1.5 &&
            !isDrawerOpen
          ) {
            setDrawerOpen(true);
          }
          //setIsStickerOverlapsMenu(overlaps);
        }
      }
    };

    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
      } else if (isDragging) {
        onDragEnd(posX, posY);
        setIsDragging(false);
        setIsStickerOverlapsMenu(false);
      }
    };

    if (isResizing || isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (isResizing || isDragging) {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [
    isResizing,
    isDragging,
    startX,
    startY,
    startWidth,
    startHeight,
    posX,
    posY,
    onDragEnd,
  ]);

  useEffect(() => {
    if (textRef.current) {
      fitty(textRef.current, {
        minSize: 25,
        maxSize: height,
        multiLine: true,
      });
    }
  }, [text, width, height]);

  return (
    <DraggableContainer
      onMouseDown={handleMouseDown}
      className='draggable-sticker pulsate'
      style={{
        left: `${posX}px`,
        top: `${posY}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: stickerColor,
        color: textColor,
        // fontSize,
      }}
    >
      <span>{text}</span>
      {/* <div ref={textRef} className='fit'>
        {text}
    
      </div> */}
      <ResizeIcon handleResizeMouseDown={handleResizeMouseDown} />
    </DraggableContainer>
  );
});
