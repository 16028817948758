import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { StickerStyled } from 'ui/stickerStyles';

const pulsate = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

export const DraggableContainer = styled(StickerStyled)`
  position: absolute;
  cursor: grab;
  user-select: none;
  align-text: center;

  .fit {
    display: inline-block;
    width: 100%;
    align-text: center;
    word-break: break-word;
    white-space: nowrap;
  }
  .span {
    padding: 25;
  }
  // &.pulsate {
  //   animation: ${pulsate} 2.8s infinite ease-in-out;
  // }
`;
