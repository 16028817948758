import { CanvasBoard } from 'components/canvas/CanvasBoard';
import { StickerCreateDrawer } from 'components/StickerCreateDrawer';

import { observer } from 'mobx-react-lite';
import React from 'react';
import axios from 'axios';

const Main = observer(() => {
  return (
    <>
      <StickerCreateDrawer />
      <CanvasBoard />
    </>
  );
});

axios.get('/api/users')
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error('There was an error fetching the users!', error);
  });

export default Main;
