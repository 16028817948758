import { makeAutoObservable } from 'mobx';
import StickerStore from './stickerStore';

export class RootStore {
  stickerStore: StickerStore;

  isDrawerOpen: boolean = false;
  isPaymentModalOpen: boolean = false;

  constructor() {
    makeAutoObservable(this);

    this.stickerStore = new StickerStore(this);
  }

  setDrawerOpen = (value: boolean) => {
    this.isDrawerOpen = value;
  };

  setPaymentModalOpen = (value: boolean) => {
    this.isPaymentModalOpen = value;
  };
}

const rootStore = new RootStore();
export default rootStore;
