import { ISticker } from 'services/stickers/interfaces';
import { IStickerDraggable } from './interfaces';
// TODO - remove after testing
const ERROR_MESSAGES = ['Offensive sticker text', 'Payment unsuccessful'];

export const handlePostStickerMock = (
  sticker: IStickerDraggable | null,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const isError = Math.random() < 0.3;

      if (isError) {
        const error = ERROR_MESSAGES[Math.floor(Math.random() * ERROR_MESSAGES.length)];
        reject(new Error(error));
      } else {
        const stickerWithId = {
          ...sticker,
          id: `${sticker?.x} - ${sticker?.y}`,
        };
        const storedStickers = JSON.parse(localStorage.getItem('stickers') || '[]');
        storedStickers.push(stickerWithId);
        localStorage.setItem('stickers', JSON.stringify(storedStickers));
        resolve();
      }
    }, 1500);
  });
};

export const loadStickerBoard = (): Promise<ISticker[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const storedStickers = JSON.parse(localStorage.getItem('stickers') || '[]');
      resolve(storedStickers);
    }, 1800);
  });
};

export const clearStickerBoard = (): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      localStorage.removeItem('stickers');
      resolve();
    }, 500);
  });
};
