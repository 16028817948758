import { makeAutoObservable } from 'mobx';
import { getStickerList } from 'services/stickers';
import { ISticker } from 'services/stickers/interfaces';
import { IStickerDraggable } from './interfaces';
import { defaultStickerTemplate, EStickerProperty } from './constants';
import { RootStore } from 'stores/rootStore';
import { clearStickerBoard, handlePostStickerMock, loadStickerBoard } from './apiMocks';
import { setErrorAlert, setSuccessAlert } from 'helpers/alertMessages';

export default class StickerStore {
  private rootStore: RootStore;

  isStickerListLoading: boolean = false;
  stickerList: ISticker[] = [];

  sticker: IStickerDraggable | null = null;
  isStickerOverlapsMenu: boolean = false;

  isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;

    this.init();
  }

  initSticker = () => {
    this.setStickerToDefault();
  };

  setSticker = (key: EStickerProperty, value: string | number) => {
    // @ts-ignore
    this.sticker[key] = value;
  };

  setStickerListLoading = (value: boolean) => {
    this.isStickerListLoading = value;
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setStickerList = (newList: ISticker[]) => {
    this.stickerList = newList;
  };

  setIsStickerOverlapsMenu = (value: boolean) => {
    this.isStickerOverlapsMenu = value;
  };

  moveSticker = (x: number, y: number) => {
    if (!this.sticker) return;

    this.sticker.x = x;
    this.sticker.y = y;
  };

  handleCreateSticker = () => {
    this.initSticker();
    this.rootStore.setDrawerOpen(true);
  };

  handleDeleteSticker = () => {
    this.sticker = null;
    this.rootStore.setDrawerOpen(false);
  };

  setStickerToDefault = () => {
    this.sticker = defaultStickerTemplate;
  };

  onStickerCreateSuccess = () => {
    setSuccessAlert('Стикер опубликован!');
    this.rootStore.setDrawerOpen(false);
    this.rootStore.setPaymentModalOpen(false);
    this.handleDeleteSticker();
    this.init();
  };

  resetStickerStore = () => {
    this.sticker = null;
  };

  handlePostSticker = async () => {
    this.setIsLoading(true);
    try {
      await handlePostStickerMock(this.sticker);
      this.onStickerCreateSuccess();
    } catch (error: any) {
      console.error('Error posting sticker:', error.message);
      setErrorAlert(error.message);
    } finally {
      this.setIsLoading(false);
    }
  };

  loadStickerBoard = async () => {
    this.setStickerListLoading(true);
    try {
      const stickers = await loadStickerBoard();
      this.setStickerList(stickers);
    } catch (error) {
      console.error('Error loading stickers:', error);
    } finally {
      this.setStickerListLoading(false);
    }
  };

  // TODO - remove after testing
  clearStickerBoard = async () => {
    try {
      await clearStickerBoard();
      this.setStickerList([]);
      console.log('Sticker board cleared!');
    } catch (error) {
      console.error('Error clearing the sticker board:', error);
    }
  };

  init = () => {
    this.loadStickerBoard();
  };
}
