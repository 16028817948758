import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/StoreProvider';
import { ECanvas } from 'constants/main';
import { CanvasBoardContainer } from './styles';
import { Draggable } from '../Draggable';
import { Sticker } from '../Sticker';
import { Loader } from 'ui/Loader';

export const CanvasBoard = observer(() => {
  const { stickerStore } = useRootStore();
  const { isStickerListLoading, stickerList, sticker, moveSticker } = stickerStore;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleDragEnd = (x: number, y: number) => {
    moveSticker(x, y);
  };

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, ECanvas.Width, ECanvas.Height);
    }
  }, []);

  return (
    <CanvasBoardContainer>
      <canvas
        ref={canvasRef}
        width={ECanvas.Width}
        height={ECanvas.Height}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <>
        {isStickerListLoading && <Loader />}
        {stickerList.map((sticker) => (
          <Sticker key={sticker.id} item={sticker} />
        ))}
        {sticker && <Draggable item={sticker} onDragEnd={handleDragEnd} />}
      </>
    </CanvasBoardContainer>
  );
});
