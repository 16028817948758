import { ESticker } from 'constants/main';
import { IStickerDraggable } from './interfaces';
import { EColors } from 'constants/colors';

export enum EStickerProperty {
  Text = 'text',
  TextColor = 'textColor',
  StickerColor = 'stickerColor',
  X = 'x',
  Y = 'y',
  Height = 'height',
  Width = 'width',
}

export const defaultStickerTemplate: IStickerDraggable = {
  [EStickerProperty.Text]: '',
  [EStickerProperty.TextColor]: EColors.Black,
  [EStickerProperty.StickerColor]: EColors.Beige,
  [EStickerProperty.X]: 0,
  [EStickerProperty.Y]: 0,
  [EStickerProperty.Height]: ESticker.DefaultHeight,
  [EStickerProperty.Width]: ESticker.DefaultWidth,
};
