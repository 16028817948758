import React, { FC } from 'react';
import { ModalContainer, ModalContent, ModalStyled } from './styles';
import { TitleWithActions } from 'ui/TitleWithActions';
import { SubmitBtn } from 'components/StickerCreateDrawer/styles';
import { useRootStore } from 'stores/StoreProvider';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';

interface IConfirmationModal {
  open: boolean;
  handleClose: () => void;
}

export const ConfirmationModal: FC<IConfirmationModal> = observer(
  ({ open, handleClose }) => {
    const { stickerStore } = useRootStore();

    const { isLoading, handlePostSticker } = stickerStore;

    return (
      <ModalStyled open={open} onClose={handleClose}>
        <ModalContainer>
          <TitleWithActions title='Подтверждение оплаты' onClick={handleClose} />

          <ModalContent>
            {Array.from({ length: 50 }).map((_, index) => (
              <p key={index}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </p>
            ))}
          </ModalContent>

          <SubmitBtn
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
            variant='contained'
            onClick={handlePostSticker}
          >
            Оплатить
          </SubmitBtn>
        </ModalContainer>
      </ModalStyled>
    );
  },
);
