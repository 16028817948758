import React from 'react';
import './styles.css';
import { observer } from 'mobx-react-lite';
import { Slider, Typography } from '@mui/material';

interface RangeInputProps {
  label: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
}

export const RangeInput: React.FC<RangeInputProps> = observer(
  ({ label, value, min, max, step, onChange }) => {
    const handleChange = (event: Event, newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        onChange(newValue);
      }
    };

    return (
      <div>
        <Typography variant='body1'>{label}</Typography>

        <div className='wrapper'>
          <Slider
            value={value}
            onChange={handleChange}
            step={step}
            min={min}
            max={max}
            valueLabelDisplay='auto'
            sx={{ width: 200 }}
          />
          <div className='range-input-value'>{value}</div>
        </div>
      </div>
    );
  },
);
