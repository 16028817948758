import styled from '@emotion/styled';
import { EColors } from 'constants/colors';
import { ECanvas } from 'constants/main';

export const CanvasBoardContainer = styled.div`
  width: ${ECanvas.Width}px;
  height: ${ECanvas.Height}px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid ${EColors.Secondary};
  position: relative;
  top: ${ECanvas.Padding}px;
  left: ${ECanvas.Padding}px;
  border-radius: 8px;
`;
