import React, { FC } from 'react';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { observer } from 'mobx-react-lite';
import { EColors } from 'constants/colors';

interface IResizeIcon {
  handleResizeMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ResizeIcon: FC<IResizeIcon> = observer(({ handleResizeMouseDown }) => (
  <div
    className='resize-handle'
    onMouseDown={(event) => handleResizeMouseDown(event)}
    style={{
      width: '10px',
      height: '10px',
      position: 'absolute',
      right: '11px',
      bottom: '9px',
      cursor: 'se-resize',
    }}
  >
    <AspectRatioIcon
      style={{
        fontSize: '20px',
        color: EColors.Secondary,
      }}
    />
  </div>
));
