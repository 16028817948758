import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useRootStore } from 'stores/StoreProvider';
import { observer } from 'mobx-react-lite';
import { FabStyled } from './styles';

export const Header = observer(() => {
  const { isDrawerOpen, setDrawerOpen, stickerStore } = useRootStore();

  const { isLoading, isStickerListLoading, sticker, handleCreateSticker } = stickerStore;

  const handleEditSticker = () => {
    setDrawerOpen(true);
  };

  return (
    <>
      {!isDrawerOpen && (
        <FabStyled
          color='primary'
          disabled={isLoading || isStickerListLoading}
          onClick={sticker ? handleEditSticker : handleCreateSticker}
          style={{ margin: '0 0 0 auto' }}
        >
          {sticker ? <EditIcon /> : <AddIcon />}
        </FabStyled>
      )}
    </>
  );
});
